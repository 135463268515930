import * as React from 'react';
import {  Container, FormSelect, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProduct, loadCategory, loadProducts, productResponseRecieved, saveProduct } from '../../actions';
import { getCategories, getProductResponse, getProducts } from '../../selectors';
import { FormComponent } from '../utils/form';
import { reset } from 'redux-form';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';

export const ProductForm = () => {
    const dispatch = useDispatch();
    const products = useSelector(getProducts);
    const categories = useSelector(getCategories);
    const response = useSelector(getProductResponse);
    React.useEffect(()=>{
        if(response) {
            toast(response.type==='SUCCESS' ? 'Saved':'Error Occured');
            if(response.type==='SUCCESS') {
                setSelected('new');
                dispatch(reset('product'));
                setProduct({
                    name: '',
                    description: '',
                    image: null,
                    category: ''
                });
            }
            dispatch(productResponseRecieved(undefined));
        }
    },[response])
    const [selected, setSelected] = React.useState('new');
    const [product, setProduct] = React.useState({
        name: '',
        description: '',
        image: null,
        category: ''
    });
    React.useEffect(()=>{
        dispatch(loadProducts());
        dispatch(loadCategory());
        setSelected('new');
    },[])
    const onChange = (event) => {
        setSelected(event.target.value);
        dispatch(reset('product'));
        if(event.target.value==='new') {
            setProduct({
                name: '',
                description: '',
                image: null,
                category: ''
            });
        }
        else {
            const prod = products.find(a=>a._id===event.target.value);
            setProduct({
                name: prod?.name,
                description: prod?.description,
                image: prod?.image,
                category: prod?.category?._id,
                id: prod?._id
            });
        }
    }
    const onSubmit= (data) => {
        console.log(data);
        if(selected==='new') {
            const formData = new FormData();
            formData.append('name', data.name);
            formData.append('description', data.description);
            formData.append('image', data.image);
            formData.append('category', data.category);
            dispatch(saveProduct({data: formData}))
        }
        else {
            const formData = new FormData();
            formData.append('name', data.name);
            formData.append('description', data.description);
            if(data.image instanceof File)
                formData.append('image', data.image);
            formData.append('category', data.category);
            dispatch(saveProduct({data: formData, id: data.id }))
        }
    }
    const onDelete = () => {
        dispatch(deleteProduct(selected))
    }
    return (
        <Container>
            <FormSelect className='mb-5' value={selected} onChange={onChange} size='lg' label="Select Product">
                <option value={'new'}>Create New</option>
                {
                    products.map(p=>(
                        <option value={p._id}>{p.name}</option>
                    ))
                }
            </FormSelect>
            {
                    selected &&
                    <>
                    {
                        selected!=='new' &&
                        <Button onClick={onDelete} color='red' size='big' className='mb-3'>Delete Product</Button>
                    }
                    <FormComponent
                        onSubmit={onSubmit}
                        saveText={selected==='new'?'Create Product':'Save Product'}
                        form='product'
                        initialValues={product}
                        fieldList={[
                            {
                                type: 'text',
                                key: 'name',
                                name: 'Product Name',
                                required: true,
                                size: '6'
                            },
                            {
                                type: 'select',
                                key: 'category',
                                name: 'Select Category',
                                values: categories.map(c=>({
                                    value: c._id, label: c.name
                                })),
                                required: true,
                                size: '6'
                            },
                            {
                                type: 'textarea',
                                key: 'description',
                                name: 'Product Description',
                                required: false,
                                size: '12'
                            },
                            {
                                type: 'file',
                                key: 'image',
                                name: 'Image',
                                required: selected==='new',
                                size: '12'
                            },
                        ]}
                    />
                    </>
                }
        </Container>
    )
}