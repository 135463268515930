import * as React from 'react';
import { Button, Image, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon, Label } from 'semantic-ui-react';
import { cookies } from '../../cookieConfig';

export const Header = () => {
    const logOut = () => {
        cookies.remove('token');
        window.location.reload();
    }
    return (
        <header>
            <Navbar fixed="top" collapseOnSelect expand="lg" className="bg-dark header">
                
                    <Navbar.Brand>
                        <Link to={'/'}>
                            <Image style={{marginLeft: '1rem'}} alt='Creative Icon' height={80} src={`${process.env.PUBLIC_URL + '/images/logo.png'}`} />
                            <Image style={{marginLeft: '1rem'}} alt='Creative Icon' className='logo-text' height={60} src={`${process.env.PUBLIC_URL + '/images/logo-text.png'}`} />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                        <Nav className='padding-right-100'>
                            <Nav.Link>
                                <Link className='nav-link-custom' to={"/about-us"}>
                                    About Us
                                </Link> 
                            </Nav.Link>
                            <Nav.Link>
                                <Link className='nav-link-custom' to={"/blogs"}>
                                    Blogs
                                </Link> 
                            </Nav.Link>
                            <Nav.Link>
                                <Link className='nav-link-custom' to={"/contact"}>
                                    Contact
                                </Link>
                            </Nav.Link>
                            {
                                cookies.get('token') &&
                                <Nav.Item>
                                    <Button onClick={logOut} size='sm'>Log Out</Button>
                                </Nav.Item>

                            }
                            <Nav.Link disabled> | </Nav.Link>
                            <Nav.Item>
                                <Label tag={false} className='background-transparent'>
                                    <Icon name='mail' size='small' />
                                    info@creativeicon.co.in
                                </Label>
                            </Nav.Item>
                            <Nav.Item>
                                <Label tag={false} className='background-transparent'>
                                    <Icon name='phone' size='small' />
                                    +91 9836492808
                                </Label>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
            </Navbar>
        </header>
    )
}